import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import {
  Button,
  FlexRow,
  Modal,
  WarningAlert,
} from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';

import {
  AccountingStatus,
  AccountingStatusForContract,
  AccountingType,
  InvoiceCycle,
  useAccountingsInTimespanLazyQuery,
  useCreateAccountingRunWorkflowMutation,
  WorkflowType,
} from '../../../graphql-types';
import { ContractNameLabel } from '../../../components/contractNameLabel';
import {
  getClosestDatetoEndDate,
  getLastSettlementDate,
  transformInvoiceCycle,
} from '../create/helpers';

import { ClosingAccountingInvoice } from './dashboardAccountingTypes';

const Wrapper = styled.div`
  padding: 12px 0;
  overflow: scroll;
  max-height: 80vh;
`;

const ContractRow = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.border};
  &:last-child {
    border-bottom: 0px;
  }
`;
const ContractRowContent = styled(FlexRow)`
  padding: 16px 0px 16px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export interface CreateAccountingWorkflow {
  name: string;
  contractId: string;
  accountingType: AccountingType;
  settlementDay?: string;
  settlementMonth?: string;
  startAt?: string;
  endAt?: string;
}

export type StartAccountingWorkflow = React.Dispatch<
  React.SetStateAction<CreateAccountingWorkflow | null>
>;

interface AccountingContractModalProps {
  invoices: ClosingAccountingInvoice;
  title: string;
  open: boolean;
  onClose: () => void;
}

const getStartPaymentPeriod = ({
  contractEndDatetime,
  contractStartDatetime,
  settlementDay,
  settlementMonth,
  invoiceCycle,
}: {
  contractEndDatetime: DateTime;
  contractStartDatetime: DateTime;
  settlementDay: number;
  settlementMonth: number;
  invoiceCycle?: InvoiceCycle | null;
}) => {
  const settlementDatetime =
    getLastSettlementDate(
      contractEndDatetime,
      invoiceCycle,
      settlementMonth,
      settlementDay,
    ) || contractStartDatetime;

  return getClosestDatetoEndDate(contractEndDatetime, [
    contractStartDatetime,
    settlementDatetime.plus({ day: 1 }),
  ]);
};

export function AccountingContractModal({
  invoices,
  open,
  title,
  onClose,
}: AccountingContractModalProps) {
  const [accountingRunExistErrors, setAccountingRunExistErrors] = useState<
    Record<string, boolean>
  >({});

  const [isLoading, setIsLoading] = useState(false);

  const [createAccountingRunWorkflowMutation] =
    useCreateAccountingRunWorkflowMutation();

  const [accountingsInTimeSpanMutation] = useAccountingsInTimespanLazyQuery({
    fetchPolicy: 'network-only',
  });
  const history = useHistory();

  const handleCreateAccountingWorkflow = async (
    invoice: ClosingAccountingInvoice[0],
  ) => {
    setIsLoading(true);
    setAccountingRunExistErrors({
      ...accountingRunExistErrors,
      [invoice.id]: false,
    });
    if (invoice) {
      const { startDate, endDate, settlement, id, label, customerName } =
        invoice;
      const contractEndDatetime = DateTime.fromISO(endDate, {
        zone: 'utc',
      });
      const contractStartDatetime = DateTime.fromISO(startDate, {
        zone: 'utc',
      });

      const invoiceCycle = transformInvoiceCycle(invoice.invoiceCycle);
      const startPaymentPeriod =
        getStartPaymentPeriod({
          contractEndDatetime,
          contractStartDatetime,
          settlementDay: +settlement.day,
          settlementMonth: +settlement.month,
          invoiceCycle,
        }) || contractStartDatetime;
      const { day, month } = settlement;
      const accountings = await accountingsInTimeSpanMutation({
        variables: {
          contractIds: [id],
          startDate: startPaymentPeriod.toISODate(),
          endDate: contractEndDatetime.toISODate(),
        },
      });
      const validAccountings = accountings.data?.accountingsInTimespan.filter(
        (accounting: AccountingStatusForContract) =>
          accounting.status !== AccountingStatus.Reversal,
      );
      if (validAccountings.length > 0) {
        setAccountingRunExistErrors({
          ...accountingRunExistErrors,
          [invoice.id]: true,
        });
        setIsLoading(false);

        return;
      }
      const { data } = await createAccountingRunWorkflowMutation({
        variables: {
          payload: {
            accountingType: AccountingType.ClosingAccountingMove,
            name: `${label}_Endabrechnung ${customerName}`,
            paymentPeriodStartAt: startPaymentPeriod.toISODate(),
            paymentPeriodEndAt: contractEndDatetime.toISODate(),
            contractToAccount: id,
            settlementDay: day ? +day : 31,
            settlementMonth: month ? +month : 12,
            workflowType: WorkflowType.Single,
            invoiceCycle,
          },
        },
      });

      const workflow = data?.createAccountingRunWorkflow;
      history.push(`/accounting/runs/${workflow?.id}`);
    }
  };
  return (
    <Modal
      isOpen={open}
      title={title}
      onRequestClose={onClose}
      contentLabel="accounting-invoice-modal"
      minWidth={600}
    >
      <Wrapper>
        {invoices.map((invoice, i) => {
          const hasError = accountingRunExistErrors[invoice.id];
          const isDisabled = new Date(invoice.endDate) > new Date() || hasError;

          return (
            <ContractRow key={`${i}-${invoice.id}-${invoice?.customerLabel}`}>
              <ContractRowContent>
                <ContractNameLabel
                  contractName={`${invoice.customerName}`}
                  contractLabel={invoice.label}
                />
                <Button
                  secondary
                  isLoading={!isDisabled && isLoading}
                  onClick={() => handleCreateAccountingWorkflow(invoice)}
                  disabled={isDisabled}
                >
                  Abrechnen
                </Button>
              </ContractRowContent>
              {hasError && (
                <WarningAlert>
                  <span>
                    Es besteht bereits eine Abrechnung für diesen Vertrag.
                    <br />
                    Setze diese Abrechnungen fort oder lösche sie, um neu zu
                    beginnen.
                  </span>
                </WarningAlert>
              )}
            </ContractRow>
          );
        })}
      </Wrapper>
    </Modal>
  );
}
